<template>
    <div class="container-common" id="container">
        <page-header :isWhite="isWhite"></page-header>

        <transition name="btn-scroll">
            <div class="btn-scroll" @click="scrollTop()" v-if="showScroll"></div>
        </transition>

        <div class="box-main" id="boxMain">
            <div class="title-main">HAMA Lab</div>
            <div class="sub-title-main">
                <span>
                H</span>armonious<br><span>A</span>dvancement for<br><span>MA</span>nKind
            </div>

            <div class="text-main">
                하마랩은 AI기술 개발을 <br> 인류를 위한 화목한 발전으로 생각하며, <br> 국내 원천기술을 개발하겠다는 <br> 일념으로 설립되었습니다. <br>
                <br> 비전과 사람을 고민하는 AI기업 하마랩은 <br> 기획부터 개발, 고도화까지 다양한 영역에서 <br> 맞춤 인공지능 솔루션을 제공합니다.

            </div>

            <div class="img-main"></div>

        </div>

        <div class="box-solution">

            <div class="img-back-solution fir "></div>
            <div class="img-back-solution sec"></div>

            <div class="title-solution">
                스마트 양식장 <br> 환경 관리 솔루션, 아쿠아누리
            </div>

            <div class="text-solution">
                IOT기술로 양식장 환경을
                <div style="display: inline-block">
                    <span>자동으로 모니터링</span>하고,
                </div>
                <br> AI 기술로 <span>수질 변화를 예측, </span>
                <div style="display: inline-block">
                    <span>어병 발생을 진단</span>합니다.
                </div>
            </div>

            <swiper :options="swiperOption" class="swiper-main">
                <swiper-slide>
                    <div class="img-smart fir"></div>
                    <div class="title-swiper">드론 촬영 & 자동 급이</div>
                </swiper-slide>
                <swiper-slide>
                    <div class="img-smart sec"></div>
                    <div class="title-swiper">수질 측정 & 변화 예측</div>
                </swiper-slide>
                <swiper-slide>
                    <div class="img-smart thi"></div>
                    <div class="title-swiper">양식장 전용 ERP</div>
                </swiper-slide>
            </swiper>

            <div class="btn-main-aqua" @click="goAqua()">
                아쿠아누리 자세히 알아보기
            </div>
        </div>


        <page-footer></page-footer>
    </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import "swiper/swiper.scss";

export default {
    name: "Home",
    mixins: [],
    components: {
        Swiper,
        SwiperSlide,
        PageFooter,
        PageHeader,


    },
    inject: [],
    provide() {
        return {};
    },
    props: {},
    data() {
        return {
            isWhite: false,
            showScroll: false,
            swiperOption: {
                slidesPerView: 1.8,
                centeredSlides: true,
                spaceBetween: 0, // swiper-slide 사이의 간격 지정
                loop: true,

            }


        };
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {

        document.getElementById('container').addEventListener('scroll', () => {
            this.setScroll(event)
        })

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        goAqua() {
            window.open('https://m.aquanuri.io')
        },
        scrollTop() {
            let container = document.getElementById('container')

            container.scrollTo({top: 0, behavior: 'smooth'});
        },

        setScroll(event) {

            let boxMain = document.getElementById('boxMain')
            if (event.target.scrollTop > boxMain.scrollTop + boxMain.offsetHeight - 48) {
                this.isWhite = true
                this.showScroll = true
            } else {
                this.isWhite = false
                this.showScroll = false
            }

        },
    },
    watch: {},
};
</script>
<style scoped></style>
