<template>
    <div class="container-header" :class="{'white' : isWhite && !activeMenu}">
        <div class="wrap-menu" :class="{'active' : activeMenu}">
            <div class="btn-menu" @click="$router.push('/about')">ABOUT US</div>
            <div class="btn-menu" @click="goAqua()">AQUA NURI</div>
            <div class="btn-menu" @click="$router.push('/contact')">CONTACT
            </div>

        </div>
        <div class="logo-header" @click="$router.push('/')">

        </div>

        <div class="btn-header" @click="setMenu()" :class="{'active' : activeMenu}">
            <div class="divider fir"></div>
            <div class="divider sec"></div>
            <div class="divider thi"></div>
            <div class="divider fou"></div>
            <div class="divider fiv"></div>
            <div class="divider six"></div>
        </div>


    </div>

</template>
<script>
export default {
    name: 'PageHeader',
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        isWhite: {
            default: () => false
        }
    },
    data() {
        return {
            activeMenu: false,

        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        goAqua() {
            window.open('https://m.aquanuri.io')
        },

        setMenu() {
            this.activeMenu = !this.activeMenu

        },

    },
    watch: {},
}
</script>

<style scoped>


</style>
