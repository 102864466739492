<template>
    <div class="container-footer">
        <div class="logo-footer"></div>
        <div class="wrap-btn-footer">
            <div class="btn-footer" @click="$router.push('/about')">ABOUT US</div>
            <div class="divider-footer"></div>
            <div class="btn-footer" @click="goAqua()">AQUA NURI</div>
            <div class="divider-footer"></div>
            <div class="btn-footer" @click="$router.push('/contact')">CONTACT</div>
        </div>
        <div class="text-copy">
            사업자등록번호 : 754-87-01524   |   대표 : 김민선
            <br>
            본사 : 경기도 성남시 수정구 달래내로 46,<div style="display: inline-block">성남글로벌융합센터 306호,606호</div>
            <br>
            연구소 : 제주특별자치도 제주시 원남 6길 58 201호
            <br>
            이메일 : biz@hamagroups.io
            <br>
            대표전화 : +82)70-5151-5884<br>
            팩스 : +82)70-7611-6107
            <br>
            <br>
            Copyright 2023 HAMA Lab All RIghts Reserved
        </div>
    </div>
</template>

<script>
export default {
    name: "PageFooter",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        goAqua() {
            window.open('https://m.aquanuri.io')
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>


