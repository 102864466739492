<template>
    <div class="container-common" id="container">
        <page-header :isWhite="isWhite"></page-header>
        <transition name="btn-scroll">
            <div class="btn-scroll" @click="scrollTop()" v-if="showScroll"></div>
        </transition>
        <div class="container-top contact">
            <div class="text-top">
                CONTACT
            </div>
        </div>

        <div class="title-contact">오시는 길</div>

        <div class="box-content-contact">
            <div class="wrap-content-contact">
                <div class="title-content-contact">이메일 주소</div>
                <div class="text-content-contact">biz@hamagroups.io</div>
            </div>
            <div class="wrap-content-contact">
                <div class="title-content-contact">전화번호</div>
                <div class="text-content-contact">+82) 70-5151-5884</div>
            </div>
            <div class="wrap-content-contact">
                <div class="title-content-contact">팩스</div>
                <div class="text-content-contact">+82) 70-7611-6107</div>
            </div>
        </div>

        <div class="container-map">
            <div class="text-map">
                본사 : 경기도 성남시 수정구 달래내로 46,
                <div style="display: inline-block">
                    성남글로벌융합센터 306호, 606호
                </div>
            </div>
            <div class="img-map fir"></div>


            <div class="text-map mt36">
                연구소 : 제주특별자치도 제주시 원남 6길 58 201호
            </div>
            <div class="img-map sec"></div>
        </div>
        <page-footer></page-footer>

    </div>


</template>
<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";

export default {
    name: "Contact",
    mixins: [],
    components: {PageFooter, PageHeader},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            isWhite: false,
            showScroll: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        document.getElementById('container').addEventListener('scroll', () => {
            this.setScroll(event)
        })

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        scrollTop() {
            let container = document.getElementById('container')

            container.scrollTo({top: 0, behavior: 'smooth'});
        },

        setScroll(event) {
            if (event.target.scrollTop > 300) {
                this.isWhite = true
                this.showScroll = true
            } else {
                this.isWhite = false
                this.showScroll = false
            }

        },
    },
    watch: {},
}
</script>

<style scoped>

</style>

