<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>
import MobileDetect from "mobile-detect";

export default {
    name: "App",
    data() {
        return {};
    },
    created() {
        let md = new MobileDetect(window.navigator.userAgent);
        if (md.mobile() == null) {
            location.href = "https://hamalab.io"
        }
    },
    computed: {
    },
    methods: {},
};
</script>
<style lang="scss">
@import "assets/scss/index";
</style>
