<template>

    <div class="container-common" id="container">
        <page-header :isWhite="isWhite"></page-header>
        <transition name="btn-scroll">
            <div class="btn-scroll" @click="scrollTop()" v-if="showScroll"></div>
        </transition>
        <div class="container-top about">
            <div class="text-top">
                ABOUT US
            </div>
        </div>

        <div class="title-about fir">
            CI
        </div>

        <div class="logo-about"></div>

        <div class="text-about-logo">
            <span>H</span>armonious <span>A</span>dvancement for <span>MA</span>nkind
        </div>
        <div class="text-about-sub">인류를 위한 화목한 발전</div>



        <div class="text-sub-color">
            HAMA의 CI에는 HAMA가 제공하는 서비스들을 통해 <br> 느끼는 <span>
            행복감을 상징하는 따뜻한 아이보리 색상과
            <br>
            발전된 모습으로 나아감을 상징하는
            <br>
            힘찬 블루 색상</span>을 담았습니다.
        </div>

        <div class="box-color">
            <div class="wrap-color">
                <div class="title-color">Yankees blue</div>
                <div class="img-color fir"></div>
                <div class="text-color">
                    <span>R</span>20
                </div>
                <div class="text-color">
                    <span>G</span>20
                </div>
                <div class="text-color">
                    <span>B</span>60
                </div>
            </div>
            <div class="wrap-color">
                <div class="title-color">Indigo</div>
                <div class="img-color sec"></div>
                <div class="text-color">
                    <span>R</span>40
                </div>
                <div class="text-color">
                    <span>G</span>60
                </div>
                <div class="text-color">
                    <span>B</span>90
                </div>
            </div>
            <div class="wrap-color">
                <div class="title-color">Waikawa Grey</div>
                <div class="img-color thi"></div>
                <div class="text-color">
                    <span>R</span>100
                </div>
                <div class="text-color">
                    <span>G</span>120
                </div>
                <div class="text-color">
                    <span>B</span>160
                </div>
            </div>
            <div class="wrap-color">
                <div class="title-color">Isabeline</div>
                <div class="img-color fou"></div>
                <div class="text-color">
                    <span>R</span>240
                </div>
                <div class="text-color">
                    <span>G</span>240
                </div>
                <div class="text-color">
                    <span>B</span>235
                </div>
            </div>
        </div>



        <div class="title-about sec">
            HISTORY
        </div>



        <div class="container-history">
            <div class="box-year" :class="activeYear">
                <div class="btn-year fir" :class="{'active' : activeYear === 'fir'}" @click="activeYear='fir'">2023</div>
                <div class="btn-year sec" :class="{'active' : activeYear === 'sec'}" @click="activeYear='sec'">2022</div>
                <div class="btn-year thi" :class="{'active' : activeYear === 'thi'}" @click="activeYear='thi'">2021</div>
                <div class="btn-year fou" :class="{'active' : activeYear === 'fou'}" @click="activeYear='fou'">2020</div>
                <div class="point-year fir"></div>
                <div class="point-year sec"></div>
                <div class="point-year thi"></div>
            </div>
            <div class="box-history">

                <transition name="history">
                    <div class="content-history" v-if="activeYear === 'fir'">
                        <div class="wrap-history">
                            <div class="date-history">
                                2023.11
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">기술신용평가(TCB) T-4등급 획득</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2023.10
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">제주도 제2연구소 설립</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2023.06
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">과기정통부 23년, 24년 데이터 바우처 지원사업 선정</div>
                                <div class="text-history">블록체인 기술검증사업 선정 (공동연구개발기관)</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2023.05
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">창업성장기술 디딤돌 연구사업 선정</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2023.04
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">환경부 에코 스타트업 사업 선정</div>
                                <div class="text-history">과기정통부 AI 바우처 기원 사업 선정</div>
                                <div class="text-history">미디어 팔레트 시장가치창출형 기술개발사업 선정</div>
                                <div class="text-history">구매조건부(구매연계형)사업 선정 (공동연구개발기관)</div>
                                <div class="text-history">KIMST 창업기업 사업화 자금 지원 프로그램 사업 선정</div>
                                <div class="text-history">제주창조혁신센터 보육 기업 선정</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2023.02
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">2023 대한민국 산업대상 수상</div>
                            </div>
                        </div>
                    </div>
                </transition>

                <transition name="history">

                    <div class="content-history" v-if="activeYear === 'sec'">
                        <div class="wrap-history">
                            <div class="date-history">
                                2022.12
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">경기지방중소벤처기업청장상 표창</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2022.11
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history mild">(주)하마랩 법인명 변경</div>
                                <div class="text-history mild">성남산업진흥원 BI지원</div>
                                <div class="text-history">성남글로벌센터 입주</div>
                                <div class="text-history">데이터 바우처 공급기업 등록</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2022.09
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">성남시 창업경진대회 우수상 수상</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2022.07
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">중소기업 스마트 지원사업 3건 수행</div>
                                <div class="text-history">특허출원(1건)</div>
                                <div class="text-history">무인기를 이용한</div>
                                <div class="text-history">스마트 양식장 모니터링 시스템</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2022.06
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">S-스타트업 왕중왕전(장려상)</div>
                                <div class="text-history">W창업패키지 B등급 수행</div>
                                <div class="text-history">청년고용우수기업 수행</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2022.05
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">R&D 재발견 프로젝트 수행</div>
                            </div>
                        </div>

                        <div class="wrap-history">
                            <div class="date-history">
                                2022.02
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">콘텐츠 진흥원 CRC 지원사업 수행</div>
                                <div class="text-history">(5G 기반 미디어 스트리밍 솔루션 개발)</div>
                                <div class="text-history">AI 바우처 공급기업 등록</div>
                            </div>
                        </div>
                    </div>
                </transition>


                <transition name="history">

                    <div class="content-history" v-if="activeYear === 'thi'">
                        <div class="wrap-history">
                            <div class="date-history">
                                2021.11
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">블록체인 기술검증 사업 완수</div>
                                <div class="text-history">(블록체인 기반 디지털 아트마켓 플레이스)</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2021.09
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">성남시 창업경진대회 우수상 수상</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2021.06
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">기업부설 연구소 설립</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2021.05
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">한국전자부품연구원 협업</div>
                                <div class="text-history">원격 수도 검침 서비스 개발</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2021.03
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">가천대학교 산학협력 및 BI 지원협약</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2021.02
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history mild">성남시 본사 이전</div>
                                <div class="text-history mild">(주)하마그룹 법인명 변경</div>
                            </div>
                        </div>
                    </div>
                </transition>


                <transition name="history">

                    <div class="content-history" v-if="activeYear === 'fou'">
                        <div class="wrap-history">
                            <div class="date-history">
                                2020.12
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">예비창업패키지 협약 종료(우수)</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2020.11
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">특허출원(2건)</div>
                                <div class="text-history">중고서적 대여 중개 시스템</div>
                                <div class="text-history">도서 교환 중개 시스템</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2020.08
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history mild">인천 IP 사업 진행</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2020.06
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history mild">뉴미디어 콘텐츠 5G 관객참여 솔루션 기술</div>
                            </div>
                        </div>
                        <div class="wrap-history">
                            <div class="date-history">
                                2020.05
                            </div>
                            <div class="wrap-text-history">
                                <div class="text-history">회사설립</div>
                                <div class="text-history">
                                    예비창업패키지 협약 시작
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>

            </div>
        </div>


        <page-footer></page-footer>
    </div>

</template>

<script>
import PageFooter from "@/components/PageFooter.vue";

export default {
    name: "About",
    mixins: [],
    components: {
        PageFooter,
    },
    inject: [],
    provide() {
        return {};
    },
    props: {},
    data() {
        return {
            isWhite: false,
            showScroll: false,
            activeYear: 'fir',
        };
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        document.getElementById('container').addEventListener('scroll', () => {
            this.setScroll(event)
        })
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setScroll(event) {
            if (event.target.scrollTop > 300) {
                this.isWhite = true
                this.showScroll = true
            } else {
                this.isWhite = false
                this.showScroll = false
            }

        },


        scrollTop() {
            let container = document.getElementById('container')

            container.scrollTo({top: 0, behavior: 'smooth'});
        },
    },
    watch: {},
};
</script>

<style scoped="scoped"></style>
