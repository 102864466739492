function setViewResolver(component) {
    return require('../views/' + component).default;
}
const layout = {
    render(h) {
        return h('router-view')
    }
}
export default [
    {
        path: '/',
        component: setViewResolver('Home'),
        name : 'Home',
        meta : { name : 'Main'},
    },
    {
        path: "/about",
        component: setViewResolver("About"),
        name: "About",
        meta: { name: "About"}
    },
    {
        path: "/contact",
        component: setViewResolver("Contact"),
        name: "Contact",
        meta: { name: "Contact"}
    },
]